import { RouteRecordRaw, createRouter, createWebHistory } from 'vue-router';

const routes: RouteRecordRaw[] = [
  {
    path: '/',
    component: () => import('@/views/Host.vue'),
  },
  {
    path: '/:channel',
    component: () => import('@/views/Guest.vue'),
  },
  {
    path: '/test',
    component: () => import('@/views/Test.vue'),
  },
];

export default createRouter({ history: createWebHistory(process.env.BASE_URL), routes });
