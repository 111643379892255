<template>
  <v-card class="ma-16">
    <v-card-title> Guest </v-card-title>

    <v-card-text>
      <v-btn class="my-16" size="x-large" color="primary" rounded="xl" variant="outlined" elevation="4" block @click="api.join()">
        <template #prepend><ion-icon name="headset-outline"></ion-icon></template>Start
      </v-btn>
      <div ref="myPlayer"></div>
      <div ref="otherPlayer"></div>
    </v-card-text>
  </v-card>
</template>

<script setup lang="ts">
import { useTemplateRef, watch } from 'vue';
import { useApi } from '@/composables/useApi';
import { join } from '@/utils/agora';

const api = useApi();

const myPlayer = useTemplateRef<HTMLDivElement>('myPlayer');
const otherPlayer = useTemplateRef<HTMLDivElement>('otherPlayer');

watch(api.token, async () => {
  console.log('token', api.token.value);
  const myPlayerDiv = await join(api.token.value, api.channel.value, api.userId.value, (otherPlayerDiv: HTMLDivElement) =>
    otherPlayer.value!.appendChild(otherPlayerDiv),
  );
  myPlayer.value!.appendChild(myPlayerDiv);
});
</script>
