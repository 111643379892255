import { createI18n, useI18n } from 'vue-i18n';
import { createVueI18nAdapter } from 'vuetify/locale/adapters/vue-i18n';

// Translations provided by Vuetify
import { en, fr } from 'vuetify/locale';
import { en as enAppTranslations } from '@/assets/i18n/en';
import { fr as frAppTranslations } from '@/assets/i18n/fr';

const messages = {
  en: { $vuetify: { ...en }, ...enAppTranslations },
  fr: { $vuetify: { ...fr }, ...frAppTranslations },
};

export const i18n = createI18n({
  legacy: false, // Vuetify does not support the legacy mode of vue-i18n
  locale: window.navigator.language,
  fallbackLocale: 'en',
  messages,
});

export const i18nAdapter = createVueI18nAdapter({ i18n, useI18n });
