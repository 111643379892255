/**
 * plugins/vuetify.ts
 *
 * Framework documentation: https://vuetifyjs.com`
 */

// Styles
import '@mdi/font/css/materialdesignicons.css';
import '@/assets/styles/main.scss';

// Composables
import { createVuetify } from 'vuetify';
import { i18nAdapter } from './i18n';

// https://vuetifyjs.com/en/introduction/why-vuetify/#feature-guides
export default createVuetify({
  theme: {
    themes: {
      light: {
        colors: {
          primary: '#243e36',
          'on-primary': '#f1f7ed',
          secondary: '#e0eec6',
          background: '#e0eec6',
          variant: '#c2a83e',
        },
      },
    },
  },
  locale: { adapter: i18nAdapter },
});
