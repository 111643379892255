<template>
  <v-sheet color="background" class="d-flex justify-center align-center">
    Channel ID: &nbsp;
    <v-sheet color="background" width="118">
      <span @click="copy">
        <v-text-field :model-value="channel" class="pt-5" rounded="pill" density="comfortable" flat :disabled="true" :loading="!channel" />
      </span>
    </v-sheet>
    &nbsp;
    <v-btn
      :icon="copied ? 'mdi-checkbox-marked-circle-outline' : 'mdi-content-copy'"
      variant="text"
      :color="copied ? 'success' : ''"
      :disabled="copied"
      @click="copy"
    >
    </v-btn>
  </v-sheet>
</template>

<script setup lang="ts">
import { ref } from 'vue';

const { channel = undefined } = defineProps<{ channel?: string }>();

const copied = ref(false);

const copy = () => {
  setTimeout(() => {
    copied.value = false;
  }, 2000);
  navigator.clipboard.writeText(window.location.origin + '/' + channel);
  copied.value = true;
};
</script>
