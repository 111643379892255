<template>
  <v-container>
    <p class="headline text-primary text-h4 text-center">Ask Me Anything<br v-if="xs" /><span v-else> - </span>1on1</p>

    <div v-if="flow === Flow.Landing">
      <p class="my-16 d-flex justify-center">Start a new 1on1 session - it's free</p>
      <p>
        <v-btn
          size="x-large"
          color="primary"
          rounded="xl"
          variant="outlined"
          elevation="4"
          block
          @click="
            flow = Flow.Call;
            api.start();
          "
        >
          <template #prepend><ion-icon name="headset-outline"></ion-icon></template>Start
        </v-btn>
      </p>
    </div>

    <div v-else-if="flow === Flow.Call" class="d-flex">
      <div class="flex-grow-1">
        <v-skeleton-loader v-if="!otherPlayerLoaded" class="ma-6" type="image" boilerplate />
        <div ref="otherPlayer" class="ma-6 h-100 player"></div>
      </div>

      <div class="d-flex flex-column">
        <ChannelId :channel="api.channel.value" />
        <v-btn class="my-1" size="x-large" color="primary" rounded="xl" elevation="4" disabled>
          <template #append><ion-icon name="chevron-down-outline"></ion-icon></template>Virtual Queue
        </v-btn>
        <div class="py-1 d-flex flex-column">
          <p v-if="api.participants.value.length === 0" class="mx-5 my-1 font-italic">No one in the queue</p>
          <v-chip
            v-for="(user, index) in api.participants.value.slice(0, 4)"
            :key="user.userName"
            class="mx-5 my-1"
            :color="index !== 0 && otherPlayerLoaded ? 'variant' : ''"
            variant="tonal"
          >
            <template #prepend><ion-icon v-if="index === 0" name="caret-forward-outline"></ion-icon></template>
            <span class="d-inline-block text-truncate" style="max-width: 185px">{{ user.userName }}</span>
          </v-chip>
          <p v-if="api.participants.value.length > 4" class="mx-5 my-1 font-italic">And {{ api.participants.value.length - 4 }} more...</p>
        </div>
        <v-btn
          class="my-1"
          size="x-large"
          color="primary"
          rounded="xl"
          elevation="4"
          :disabled="!otherPlayerLoaded"
          @click="otherPlayerLoaded ? api.kick() : api.next()"
        >
          <template #prepend><ion-icon :name="otherPlayerLoaded ? 'remove-circle-outline' : 'play-skip-forward-outline'"></ion-icon></template>
          {{ otherPlayerLoaded ? 'Kick' : 'Next' }}
        </v-btn>
        <v-btn
          class="my-1"
          size="x-large"
          color="primary"
          rounded="xl"
          variant="outlined"
          elevation="4"
          @click="
            agora.leave();
            flow = Flow.Finished;
          "
        >
          <template #prepend><ion-icon name="videocam-off-outline"></ion-icon></template>Terminate
        </v-btn>
        <v-divider class="my-1" />
        <v-skeleton-loader v-if="!myPlayerLoaded" class="ma-1" type="image" />
        <div ref="myPlayer" class="ma-1 player"></div>
      </div>
    </div>

    <p v-else>
      We hope you enjoyed your chat(s)! Want to start another session?
      <v-btn size="x-large" color="primary" rounded="xl" variant="outlined" elevation="4" block @click="flow = Flow.Call">
        <template #prepend><ion-icon name="headset-outline"></ion-icon></template>Start
      </v-btn>
    </p>
  </v-container>
</template>

<script setup lang="ts">
import { Ref, ref, useTemplateRef, watch } from 'vue';
import { useDisplay } from 'vuetify';
import { useApi } from '@/composables/useApi';
import * as agora from '@/utils/agora';

const { xs } = useDisplay();
const api = useApi();

enum Flow {
  Landing = 'landing',
  Call = 'call',
  Finished = 'finished',
}
const flow = ref<Flow>(Flow.Landing);

const myPlayer = useTemplateRef<HTMLDivElement>('myPlayer');
const myPlayerLoaded = ref(false);
const otherPlayer = useTemplateRef<HTMLDivElement>('otherPlayer');
const otherPlayerLoaded = ref(false);
const watchForDivChildren = (element: HTMLDivElement, loaded: Ref<boolean>) => {
  const observer = new MutationObserver(() => {
    if (element.children.length > 0) {
      loaded.value = true;
      observer.disconnect();
    }
  });
  observer.observe(element, { childList: true });
};

watch(api.channel, async () => {
  const myPlayerDiv = await agora.join(api.token.value, api.channel.value, api.userId.value, (otherPlayerDiv: HTMLDivElement) => {
    watchForDivChildren(otherPlayer.value!, otherPlayerLoaded);
    otherPlayer.value!.appendChild(otherPlayerDiv);
  });
  watchForDivChildren(myPlayer.value!, myPlayerLoaded);
  myPlayer.value!.appendChild(myPlayerDiv);
});
</script>
