import AgoraRTC, { IAgoraRTCClient, ICameraVideoTrack, IMicrophoneAudioTrack } from 'agora-rtc-sdk-ng';

const APP_ID = '28e6c12e43e9415ba72d24e91c55ccef';

let client: IAgoraRTCClient | undefined = undefined;
let localAudioTrack: IMicrophoneAudioTrack | undefined = undefined;
let localVideoTrack: ICameraVideoTrack | undefined = undefined;

const createClient = async (userJoins: (player: HTMLDivElement) => void) => {
  client = AgoraRTC.createClient({ mode: 'rtc', codec: 'vp8' });

  client.on('user-published', async (user, mediaType) => {
    await client?.subscribe(user, mediaType);

    if (mediaType === 'video') {
      const remoteVideoTrack = user.videoTrack;
      const remotePlayerContainer = document.createElement('div');
      remotePlayerContainer.id = user.uid.toString();
      remotePlayerContainer.style.width = '100%';
      remotePlayerContainer.style.height = '100%';
      userJoins(remotePlayerContainer);
      remoteVideoTrack?.play(remotePlayerContainer);
    }

    if (mediaType === 'audio') {
      const remoteAudioTrack = user.audioTrack;
      remoteAudioTrack?.play();
    }

    client?.on('user-unpublished', (user) => {
      const remotePlayerContainer = document.getElementById(user.uid.toString());
      remotePlayerContainer?.remove();
    });
  });
};

export const join = async (token: string, channel: string, uuid: string, userJoins: (player: HTMLDivElement) => void) => {
  if (!client) {
    await createClient(userJoins);
  }

  console.log('joining:', APP_ID, channel, token, uuid);
  await client?.join(APP_ID, channel, token, uuid);
  localAudioTrack = await AgoraRTC.createMicrophoneAudioTrack();
  localVideoTrack = await AgoraRTC.createCameraVideoTrack();
  await client?.publish([localAudioTrack, localVideoTrack]);
  const localPlayerContainer = document.createElement('div');
  localPlayerContainer.id = uuid;
  localPlayerContainer.style.width = '100%';
  localPlayerContainer.style.height = '200px';

  localVideoTrack.play(localPlayerContainer);
  return localPlayerContainer;
};

export const leave = async (uuid: string) => {
  if (!client) {
    return;
  }

  localAudioTrack?.close();
  localVideoTrack?.close();

  const localPlayerContainer = document.getElementById(uuid);
  if (localPlayerContainer) {
    localPlayerContainer.remove();
  }

  client?.remoteUsers.forEach((user) => {
    const playerContainer = document.getElementById(user.uid.toString());
    if (playerContainer) playerContainer.remove();
  });

  await client?.leave();
};
