import { onMounted, ref } from 'vue';
import { w3cwebsocket as WebSocketClient } from 'websocket';
import { useRoute } from 'vue-router';

const WS_URL = 'wss://wm3y4y2hbd.execute-api.eu-west-3.amazonaws.com/dev/';

const send = <T extends { channel: string; userName?: string }>(client: WebSocketClient, action: string, body?: T) =>
  client.send(JSON.stringify({ action, ...body }));

interface Participant {
  connectionId: string;
  userName: string;
}

export const useApi = () => {
  const client = ref<WebSocketClient | undefined>(undefined);

  const route = useRoute();

  const state = ref<'open' | 'closed'>('closed');
  const token = ref<string | undefined>(undefined);
  const channel = ref<string | undefined>(undefined);
  const userId = ref<string | undefined>(undefined);
  const participants = ref<Participant[]>([]);

  // HOST commands
  const start = () => send(client.value!, 'start');
  const end = () => send(client.value!, 'end', { channel: channel.value! });
  const kick = () => send(client.value!, 'kick', { channel: channel.value! });
  const next = () => send(client.value!, 'next', { channel: channel.value! });
  // PARTICIPANT commands
  const join = (userName: string) => {
    channel.value = route.path.slice(1);
    send(client.value!, 'join', { channel: channel.value, userName });
  };
  const leave = (channel: string) => send(client.value!, 'leave', { channel });

  const onMessage = (message) => {
    const data = JSON.parse(message.data as string);
    console.log('Message:', data);

    token.value = data.token ?? token.value;
    channel.value = data.channelId ?? channel.value;
    userId.value = data.userId ?? userId.value;
    participants.value = data.participants ?? participants.value;
  };

  onMounted(() => {
    client.value = new WebSocketClient(WS_URL);
    client.value.onmessage = onMessage;
  });

  return { start, end, kick, next, join, leave, state, token, channel, userId, participants };
};
