<template>
  <v-sheet class="ma-16">
    <v-sheet class="my-10 d-flex justify-center">
      <v-btn class="mx-16" color="primary" rounded="pill" size="large" :loading="joinLoading" :disabled="inCall" @click="join">Join</v-btn>
      <v-btn class="mx-16" color="primary" rounded="pill" size="large" :loading="leaveLoading" :disabled="!inCall" @click="leave">Leave</v-btn>
    </v-sheet>

    <v-sheet v-if="!isJoiningChannel" class="my-10 d-flex justify-center align-center">
      Your channel ID: &nbsp;
      <v-sheet width="118">
        <v-text-field v-model="channel" class="pt-5" rounded="pill" density="comfortable" flat :disabled="true" />
      </v-sheet>
      &nbsp;
      <v-btn
        :icon="copied ? 'mdi-checkbox-marked-circle-outline' : 'mdi-content-copy'"
        variant="text"
        :color="copied ? 'success' : ''"
        :disabled="copied"
        @click="copy"
      ></v-btn>
    </v-sheet>

    <v-card class="pa-10 d-flex justify-center" border="xl" color="primary" rounded="xl" :loading="joinLoading"><div ref="video" /></v-card>
  </v-sheet>
</template>

<script setup lang="ts">
import { onMounted, useTemplateRef, ref, reactive, computed } from 'vue';
import { useRoute } from 'vue-router';
// import { v4 as uuidv4 } from 'uuid';
import AgoraRTC, { IAgoraRTCClient, ICameraVideoTrack, IMicrophoneAudioTrack } from 'agora-rtc-sdk-ng';
import { w3cwebsocket as WebSocketClient } from 'websocket';

const route = useRoute();

const videoDiv = useTemplateRef<HTMLDivElement>('video');
const inCall = ref(false);
const joinLoading = ref(false);
const leaveLoading = ref(false);
const copied = ref(false);
const token = ref<string | undefined>(
  '007eJxTYPjnflh92/u/k6+JaNp2ORceE4+r8/24ae/DDRv/vZ1j2qyjwGBkkWqWbGiUamKcamliaJqUaG6UYmSSammYbGqanJyaJntPJF2Aj4GhR/cZMyMDIwMLAyMDiM8EJpnBJAuY5GJINE/STU3STTJLFGBIs0jNTk4LK810j3T2yEm3BQA4KSfK',
);
const copy = () => {
  setTimeout(() => {
    copied.value = false;
  }, 2000);
  navigator.clipboard.writeText(window.location.origin + '/' + channel.value);
  copied.value = true;
};

const rtc: { localAudioTrack?: IMicrophoneAudioTrack; localVideoTrack?: ICameraVideoTrack; client?: IAgoraRTCClient } = {
  localAudioTrack: undefined,
  localVideoTrack: undefined,
  client: undefined,
};

const isJoiningChannel = computed(() => route.path.length > 1);
const channel = computed(() => {
  return 'a7b-eb-b6a';
  // if (isJoiningChannel.value) {
  //   return route.path.slice(1);
  // }
  // const channel = uuidv4().split('-')[0];
  // return `${channel.slice(0, 3)}-${channel.slice(4, 6)}-${channel.slice(-3)}`;
});

const options = reactive({
  // Pass your app ID here.
  appId: '28e6c12e43e9415ba72d24e91c55ccef',
  // Set the user ID.
  uid: 'f8ekcfVuiGYCHlg=',
});

onMounted(() => {
  const wsClient = new WebSocketClient('wss://wm3y4y2hbd.execute-api.eu-west-3.amazonaws.com/dev/');
  wsClient.onopen = () => {
    wsClient.send(JSON.stringify({ action: 'connect', channel: channel.value, userId: options.uid }));
  };
  wsClient.onmessage = (message) => {
    console.log('WebSocket message received:', message.data);
    // token.value = JSON.parse(message.data as string).token;
  };
  wsClient.onclose = () => {
    console.log('WebSocket connection closed');
  };

  // Create an AgoraRTCClient object.
  rtc.client = AgoraRTC.createClient({ mode: 'rtc', codec: 'vp8' });

  // Listen for the "user-published" event, from which you can get an AgoraRTCRemoteUser object.
  rtc.client.on('user-published', async (user, mediaType) => {
    // Subscribe to the remote user when the SDK triggers the "user-published" event
    await rtc.client?.subscribe(user, mediaType);
    console.log('subscribe success');

    // If the remote user publishes a video track.
    if (mediaType === 'video') {
      // Get the RemoteVideoTrack object in the AgoraRTCRemoteUser object.
      const remoteVideoTrack = user.videoTrack;
      // Dynamically create a container in the form of a DIV element for playing the remote video track.
      const remotePlayerContainer = document.createElement('div');
      // Specify the ID of the DIV container. You can use the uid of the remote user.
      remotePlayerContainer.id = user.uid.toString();
      remotePlayerContainer.textContent = 'Remote user ' + user.uid.toString();
      remotePlayerContainer.style.width = '640px';
      remotePlayerContainer.style.height = '480px';
      videoDiv.value!.append(remotePlayerContainer);

      // Play the remote video track.
      // Pass a DIV container and the SDK dynamically creates a player in the container for playing the remote video track.
      remoteVideoTrack?.play(remotePlayerContainer);
    }

    // If the remote user publishes an audio track.
    if (mediaType === 'audio') {
      // Get the RemoteAudioTrack object in the AgoraRTCRemoteUser object.
      const remoteAudioTrack = user.audioTrack;
      // Play the remote audio track. No need to pass any DOM element.
      remoteAudioTrack?.play();
    }

    // Listen for the "user-unpublished" event
    rtc.client?.on('user-unpublished', (user) => {
      // Get the dynamically created DIV container.
      const remotePlayerContainer = document.getElementById(user.uid.toString());
      // Destroy the container.
      remotePlayerContainer?.remove();
    });
  });
});

const join = async () => {
  joinLoading.value = true;
  // Join an RTC channel.
  await rtc.client?.join(options.appId, channel.value, token.value!, options.uid);
  // Create a local audio track from the audio sampled by a microphone.
  rtc.localAudioTrack = await AgoraRTC.createMicrophoneAudioTrack();
  // Create a local video track from the video captured by a camera.
  rtc.localVideoTrack = await AgoraRTC.createCameraVideoTrack();
  // Publish the local audio and video tracks to the RTC channel.
  await rtc.client?.publish([rtc.localAudioTrack, rtc.localVideoTrack]);
  // Dynamically create a container in the form of a DIV element for playing the local video track.
  const localPlayerContainer = document.createElement('div');
  // Specify the ID of the DIV container. You can use the uid of the local user.
  localPlayerContainer.id = options.uid.toString();
  localPlayerContainer.textContent = 'Local user ' + options.uid;
  localPlayerContainer.style.width = '640px';
  localPlayerContainer.style.height = '480px';
  videoDiv.value!.append(localPlayerContainer);

  // Play the local video track.
  // Pass the DIV container and the SDK dynamically creates a player in the container for playing the local video track.
  rtc.localVideoTrack.play(localPlayerContainer);
  console.log('publish success!');
  inCall.value = true;
  joinLoading.value = false;
};

const leave = async () => {
  leaveLoading.value = true;
  // Destroy the local audio and video tracks.
  rtc.localAudioTrack?.close();
  rtc.localVideoTrack?.close();

  // Remove the container for the local video track.
  const localPlayerContainer = document.getElementById(options.uid.toString());
  if (localPlayerContainer) {
    localPlayerContainer.remove();
  }

  // Traverse all remote users.
  rtc.client?.remoteUsers.forEach((user) => {
    // Destroy the dynamically created DIV containers.
    const playerContainer = document.getElementById(user.uid.toString());
    if (playerContainer) playerContainer.remove();
  });

  // Leave the channel.
  await rtc.client?.leave();
  inCall.value = false;
  leaveLoading.value = false;
};
</script>
